<template>
  <v-container
    fill-height
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <v-row justify="center" align="center">
      <v-col cols="8">
        <h1 class="text-center">
          Kaydını tamamladın! <br />
          Giriş sayfasına yönlendiriliyorsun. <br />
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EndingPage",
  created() {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("activeForm");
    setTimeout(() => {
      this.$router.push({ name: "login" });
    }, 5000);
  },
};
</script>

<style scoped>
h1 {
  font-size: calc(1.5rem + 2vw);
  font-family: Georgia, "Times New Roman", Times, serif;
  color: whitesmoke;
  text-shadow: 2px 2px 4px #000000;
}
</style>
